
import './App.css';
import React, { useEffect, useState } from 'react';
function App() {

  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const countdownDate = new Date('October 9, 2024 00:00:00').getTime();

    const countdownFunction = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(countdownFunction);
        setTimeRemaining('¡Evento finalizado!');
      } else {
        setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(countdownFunction); // Limpiamos el intervalo al desmontar el componente
  }, []);


  return (
    <div className="App">
      <header className="App-header">
        <img src="/KITSUNELOGO.jpg"className="App-logo" alt="logo" />
        <p>
      <strog>Falta Poco!!</strog>
        </p>
        <p>
          Estamos armando la tienda, se vienen Juegos de Mesa, sumate a compartir este mundo con nosotros.
        </p>
    
        {/* Aquí mostramos la cuenta regresiva */}
       
        <p className='count-down'>{timeRemaining}</p>
      
       
        <div className='contacto'>
        <a href="https://www.instagram.com/kitsunegames.ar" target="_blank" rel="noopener noreferrer">
        <img src="/instagram.png"className="ig" alt="logo" /></a>
        <a href="https://wa.me/5491130937930?text=Hola%2C%20somos%20Kitsunegames%2C%20%C2%BFC%C3%B3mo%20podemos%20ayudarte%3F" target="_blank" rel="noopener noreferrer">
        <img src="/wapp.png"className="ig" alt="logo" /></a>
        <a href="mailto:contacto@kitsunegames.ar" target="_blank" rel="noopener noreferrer">
        <img src="/email.png"className="ig" alt="logo" /></a>
        </div>
      </header>
   
    </div>

    
  );
}

export default App;
